import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getDishSubscription(params) {
  return request({
    url: `${ENDPOINT.DISH_SUBCRIPTION}`,
    method: 'get',
    params
  })
}

export function updateDishSubscription(data) {
  return request({
    url: `${ENDPOINT.DISH_SUBCRIPTION}`,
    method: 'patch',
    data
  })
}
